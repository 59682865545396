import {useEffect, useRef, useState} from 'react';
import {addDoc, doc, getDoc, serverTimestamp} from "firebase/firestore";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import {LoadingButton} from '@mui/lab';
import {useNavigate} from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useAuthState} from "react-firebase-hooks/auth";
import parsePhoneNumber from "libphonenumber-js";
import {auth, database} from "../components/firebaseConfig";
import {useAuth} from "../contexts/CustomAuthProvider";
import {insurances} from "../const/insurances";
import {races} from "../const/races"
import {states} from "../const/states";
import {usaStates} from "../const/usaStates";
import {sexes} from "../const/sexes"
import {ethnicities} from "../const/ethnicities"
import {defaultPatientTypes} from "../const/defaultPatientTypes"
import {entities} from "../const/entities"
import {identificationTypes} from "../const/identificationTypes"
import {
  ControlledAutocomplete,
  ControlledDesktopDatePicker,
  ControlledMuiPhoneNumber,
  ControlledSelect
} from "../utils/ControlledComponents";
import {countries} from "../const/countries";
import {rolesViews} from "../const/roles";
import {vaccines} from "../const/vaccines";
import {useForm} from "react-hook-form";
import {CustomSignaturePad} from "../components/SignaturePad";
import {useClientIP} from "../hooks/useClientIP";
import Page from "../components/Page";
import axios from "axios";
import {envConfig} from "../envConfig";

export default function RegisterPatient() {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [defaultFacility, setDefaultFacility] = useState(null);
  const [facilityData, setFacilityData] = useState(
      {patientCategories: defaultPatientTypes});
  const watchingAddressCountry = watch("address.country");
  const watchingIdentificationType = watch("identification.type");
  const watchBillTo = watch("billTo");
  const watchingVaccineType = watch("vaccine.type");

  const sweetAlert = withReactContent(Swal);
  const navigate = useNavigate();

  const [modifyingCredits, setModifyingCredits] = useState(false);

  const {userData} = useAuth();
  const signPad = useRef(null);
  const [currentUser] = useAuthState(auth);
  const clientIP = useClientIP();

  useEffect(() => {
    setDefaultFacility(userData?.preferences?.defaultFacility);
  }, [userData]);

  useEffect(() => {
    if (!defaultFacility) {
      return;
    }
    getDoc(doc(database.facilities, defaultFacility))
    .then(facility => {
      setFacilityData({
        patientCategories: defaultPatientTypes,
        ...facility.data(),
      });
    });
  }, [defaultFacility]);

  const onError = () => {
    sweetAlert.fire({
      title: <p>Please correct invalid fields.</p>,
      icon: "error",
      timer: 3000,
      toast: true,
      position: 'bottom-end',
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
  }

  const onSubmit = async (data) => {
    if (signPad.current.isEmpty()) {
      sweetAlert.fire({
        title: <p>Please sign the form!</p>,
        icon: "error",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
      return;
    }
    setLoading(true);
    data.phone = parsePhoneNumber(data.phone)?.number;
    let response;
    try {
      response = await axios.post(
          envConfig.currentAppEngineVerify + "checkDuplicates",
          {
            dob: data.dob,
            phone: data.phone,
          },
          // {headers: {recaptcha: token}}
      );
    } catch (error) {
      console.log(error.response)
      sweetAlert.fire({
        title: <p>{error.response?.data?.status}</p>,
        icon: "error",
        timer: 6000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
      setLoading(false);
      return;
    }
    if (response?.data?.duplicate) {
      sweetAlert.fire({
        title: <p>Duplicate record found! Please contact your friendly
          neighborhood admin for support.</p>,
        icon: "error",
        timer: 6000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
      setLoading(false);
      return;
    }
    const pcrCredit = parseInt(data.credit.pcr);
    data.credit.pcr = isNaN(pcrCredit) ? 0 : pcrCredit;
    const antigenCredit = parseInt(data.credit.antigen);
    data.credit.antigen = isNaN(antigenCredit) ? 0 : antigenCredit;
    data.searchField = data?.fname?.toLowerCase();
    data.ssn = data?.ssn?.replaceAll(/[\s-]+/g, "");
    data.facilityId = defaultFacility;
    const signatureImage = signPad.current.toDataURL("image/svg+xml");
    data.author = {
      "submittedBy": currentUser.uid,
      "timestamp": serverTimestamp(),
      ip: clientIP,
      signature: signatureImage,
    };
    addDoc(database.patients, data).then(() => {
      sweetAlert.fire({
        title: <p>Added Patient!</p>,
        icon: "success",
        text: "The patient has been added.",
      }).then();
      navigate("/PatientRecords");
      setLoading(false);
    }).catch((error) => {
      sweetAlert.fire({
        title: <p>Could not add patient record.</p>,
        text: error,
        icon: "error",
      }).then();
      setLoading(false);
    });
  }

  const toggleModifyCredits = () => {
    setModifyingCredits(!modifyingCredits)
  }

  return (
      <Page title={"LabPort | Register Patient"}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            {/*Patient Info*/}
            <Grid item xs={12}>
              <Typography variant={"h4"}>
                Patient Info
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
              <TextField
                  inputProps={{...register("fname")}}
                  label={"First Name"}
                  autoComplete={"new-password"}
                  required
                  fullWidth/>
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
              <TextField
                  inputProps={{...register("lname")}}
                  label={"Last Name"}
                  autoComplete={"new-password"}
                  required
                  fullWidth/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <ControlledDesktopDatePicker
                  name={"dob"}
                  control={control}
                  label={"Date of Birth"}
                  autoComplete={"new-password"}
                  required
                  inputFormat={"MM/dd/yyyy"}
                  minimumDate={new Date("1900-01-01")}
                  disableFuture/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <ControlledMuiPhoneNumber
                  name={"phone"}
                  control={control}
                  label={"Phone Number"}
                  autoComplete={"new-password"}
                  required
                  type={"tel"}
                  variant={"outlined"}
                  defaultCountry={'us'}
                  disableAreaCodes
                  fullWidth/>
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
              <TextField
                  inputProps={{...register("email")}}
                  label={"Email"}
                  autoComplete={"new-password"}
                  required
                  type={"email"}
                  fullWidth/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <ControlledSelect
                  name={"sex"}
                  control={control}
                  items={sexes}
                  label={"Sex"}
                  required/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <ControlledSelect
                  name={"race"}
                  control={control}
                  items={races}
                  label={"Race"}
                  required/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <ControlledSelect
                  name={"ethnicity"}
                  control={control}
                  items={ethnicities}
                  label={"Ethnicity"}
                  required/>
            </Grid>
            {facilityData?.patientCategories?.length > 0 &&
                <Grid item lg={3} sm={6} xs={12}>
                  <ControlledSelect
                      name={"patientType"}
                      control={control}
                      items={facilityData.patientCategories}
                      defaultValue={""}
                      label={"Patient Category"}
                      required={facilityData.settings?.enforcePatientType}/>
                </Grid>}
            {(facilityData?.settings?.ssn && watchingAddressCountry
                    === "United States") &&
                <Grid item lg={3} sm={6} xs={12}>
                  <TextField
                      inputProps={{
                        ...register("ssn", {
                          pattern: /^[-\s0-9\b]+$/,
                        }),
                      }}
                      error={Boolean(errors.ssn)}
                      helperText={Boolean(errors.ssn)
                          && "Must only contain numbers."}
                      label={"Social Security Number or TIN"}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.enforceSsn}
                      variant={"outlined"}
                      fullWidth/>
                </Grid>}
            {/*Employment Info*/}
            {facilityData?.settings?.collectEmployeeInfo &&
                <>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"h4"}>
                      Employment Info
                    </Typography>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <TextField
                        inputProps={{...register("meta.employeeId")}}
                        label={"Employee ID"}
                        required
                        autoComplete={"new-password"}
                        variant={"outlined"}
                        fullWidth/>
                  </Grid>
                  {facilityData?.departments?.length > 0 &&
                      <Grid item lg={4} sm={6} xs={12}>
                        <ControlledSelect
                            name={"meta.employeeDept"}
                            control={control}
                            items={facilityData.departments}
                            label={"Department"}
                            required/>
                      </Grid>
                  }
                  {facilityData?.locations?.length > 0 &&
                      <Grid item lg={4} sm={6} xs={12}>
                        <ControlledSelect
                            name={"meta.employeeLocation"}
                            control={control}
                            items={facilityData.locations}
                            label={"Location"}
                            required/>
                      </Grid>
                  }
                </>
            }
            {/*Location Details*/}
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"h4"}>
                Location Details
              </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TextField
                  inputProps={{...register("address.line1")}}
                  label={"Address Line 1"}
                  autoComplete={"new-password"}
                  required
                  fullWidth/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <TextField
                  inputProps={{...register("address.city")}}
                  label={"City"}
                  autoComplete={"new-password"}
                  required
                  fullWidth/>
            </Grid>
            {(watchingAddressCountry === "United States"
                    || !Boolean(watchingAddressCountry)) &&
                <Grid item lg={3} sm={6} xs={12}>
                  <ControlledSelect
                      name={"address.state"}
                      control={control}
                      items={usaStates}
                      label={"State"}
                      required/>
                </Grid>}
            {(watchingAddressCountry === "United States"
                    || watchingAddressCountry === "Canada"
                    || !Boolean(watchingAddressCountry)) &&
                <Grid item lg={2} sm={6} xs={12}>
                  <TextField
                      inputProps={{...register("address.zip")}}
                      label={"Postal Code"}
                      autoComplete={"new-password"}
                      required
                      fullWidth/>
                </Grid>}
            <Grid item lg={4} sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"address.country"}
                  control={control}
                  label={"Country of Residence"}
                  options={countries}
                  autoComplete={"new-password"}
                  required
                  variant={"outlined"}/>
            </Grid>
            {/*Billing*/}
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"h4"}>
                Billing
              </Typography>
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
              <TextField
                  inputProps={{...register("credit.antigen")}}
                  disabled={!modifyingCredits}
                  label={"Antigen Credit(s)"}
                  autoComplete={"new-password"}
                  defaultValue={"0"}
                  type={"number"}
                  required
                  fullWidth/>
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
              <TextField
                  inputProps={{...register("credit.pcr")}}
                  disabled={!modifyingCredits}
                  label={"PCR Credit(s)"}
                  autoComplete={"new-password"}
                  defaultValue={"0"}
                  type={"number"}
                  required
                  fullWidth/>
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
              <Button
                  variant={"contained"}
                  color={"info"}
                  onClick={toggleModifyCredits}
                  disabled={!rolesViews[userData?.roles?.role]?.modifyCredits}>
                Modify credits
              </Button>
            </Grid>
            {/*Insurance Info*/}
            <Grid item lg={3} sm={6} xs={6}>
              <ControlledSelect
                  name={"billTo"}
                  control={control}
                  items={entities}
                  label={"Bill to"}
                  required={facilityData?.settings?.insurance}/>
            </Grid>
            {watchBillTo === "Insurance" && <><Grid item xs={12}>
              <Typography variant={"h4"}>
                Insurance Info
              </Typography>
            </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <ControlledAutocomplete
                    name={"insurance.carrier"}
                    control={control}
                    label={"Insurance Providers"}
                    options={insurances}
                    autoComplete={"new-password"}
                    freeSolo
                    required={facilityData?.settings?.insurance}
                    variant={"outlined"}/>
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                    inputProps={{...register("insurance.policyNumber")}}
                    label={"Policy #"}
                    autoComplete={"new-password"}
                    required={facilityData?.settings?.insurance}
                    fullWidth/>
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                    inputProps={{...register("insurance.groupNumber")}}
                    label={"Group #"}
                    autoComplete={"new-password"}
                    required={facilityData?.settings?.insurance}
                    fullWidth/>
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                    inputProps={{...register("insurance.subscriberName")}}
                    label={"Subscriber Name"}
                    autoComplete={"new-password"}
                    required={facilityData?.settings?.insurance}
                    fullWidth/>
              </Grid>
            </>}
            {/*Identification Details*/}
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"h4"}>
                Identification Details
              </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"identification.type"}
                  control={control}
                  label={"Identification Type"}
                  options={Object.values(identificationTypes)}
                  autoComplete={"new-password"}
                  freeSolo
                  required={facilityData?.settings?.id}
                  variant={"outlined"}/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <TextField
                  inputProps={{...register("identification.number")}}
                  label={"Identification #"}
                  autoComplete={"new-password"}
                  required={facilityData?.settings?.id}
                  fullWidth/>
            </Grid>
            {!watchingIdentificationType?.toLowerCase().includes("ssn") &&
                <Grid item lg={2} sm={6} xs={12}>
                  <ControlledDesktopDatePicker
                      name={"identification.exp"}
                      control={control}
                      label={"Expiration Date"}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.id}
                      minimumDate={new Date("1900-01-01")}
                      inputFormat={"MM/dd/yyyy"}/>
                </Grid>}
            <Grid item lg={3} sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"identification.state"}
                  control={control}
                  label={"Issuance State"}
                  options={states}
                  autoComplete={"new-password"}
                  required={facilityData?.settings?.id}
                  variant={"outlined"}/>
            </Grid>
            {/*Vaccine Info*/}
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"h4"}>
                Vaccine Status
              </Typography>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"vaccine.type"}
                  control={control}
                  label={"Vaccine(s) taken"}
                  options={vaccines}
                  required={facilityData?.settings?.enforceVaccineCard}
                  multiple
                  autoComplete={"new-password"}
                  autoCompleteProps={{
                    limitTags: 1,
                  }}
                  variant={"outlined"}/>
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
              <ControlledDesktopDatePicker
                  name={"vaccine.firstDoseDate"}
                  control={control}
                  label={"Date of 1st Dose"}
                  autoComplete={"new-password"}
                  required={facilityData?.settings?.enforceVaccineCard}
                  inputFormat={"MM/dd/yyyy"}
                  minimumDate={new Date("2020-01-01")}
                  disableFuture/>
            </Grid>
            {(watchingVaccineType?.some(v => v.includes("Moderna"))
                    || watchingVaccineType?.some(v => v.includes("Pfizer"))) &&
                <Grid item lg={2} sm={6} xs={12}>
                  <ControlledDesktopDatePicker
                      name={"vaccine.secondDoseDate"}
                      control={control}
                      label={"Date of 2nd Dose"}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.enforceVaccineCard}
                      inputFormat={"MM/dd/yyyy"}
                      minimumDate={new Date("2020-01-01")}
                      disableFuture/>
                </Grid>}
            <Grid item lg={2} sm={6} xs={12}>
              <ControlledDesktopDatePicker
                  name={"vaccine.boosterDate"}
                  control={control}
                  label={"Date of Booster"}
                  autoComplete={"new-password"}
                  required={facilityData?.settings?.enforceVaccineCard}
                  inputFormat={"MM/dd/yyyy"}
                  minimumDate={new Date("2020-01-01")}
                  disableFuture/>
            </Grid>
            {/*Sign and Submit*/}
            <Grid item xs={12}>
              <FormControlLabel
                  value={"end"}
                  control={<Checkbox required color={"primary"}/>}
                  label="I certify that the above information is correct and
                understand that patient name and date of birth cannot be
                altered after submission. The patient has been advised that
                they or their Insurance Provider will be billed by our
                laboratories for any tests performed. Failure to input correct
                insurance information may result in the facility receiving a
                bill for the tests performed."
                  labelPlacement={"end"}/>
            </Grid>
            <Grid item xs={12}>
              <Typography
                  gutterBottom
                  color='textSecondary'
                  style={{fontSize: "1rem"}}>
                Patients must be registered prior to submitting a requisition
                form. Please retain a physical copy of the Patient's insurance
                card and photo identification in your files.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomSignaturePad ref={signPad}/>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                  loading={loading}
                  type={"submit"}
                  variant={"contained"}
                  fullWidth>
                Register Patient
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Page>
  );
}
