import {useEffect, useRef, useState} from "react";
import {
  Button,
  FormGroup,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {doc, updateDoc} from "firebase/firestore";
import {Link} from "react-router-dom";
import {useFieldArray, useForm} from "react-hook-form";
import {useSnackbar} from "notistack";
import {useAuth} from "../contexts/CustomAuthProvider";
import {database} from "./firebaseConfig";
import PhysicianList from "./PhysicianList";
import {NotifyList} from "./NotifyList";
import {ControlledSwitch} from "../utils/ControlledComponents";
import {LocalTimeRepresentation} from "./LocalTimeRepresentation";

export default function EditFacilityForm(props) {
  const {facilityData} = props;
  const {enqueueSnackbar} = useSnackbar();
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    control,
    clearErrors,
    watch,
    setError,
    formState: {errors},
  } = useForm(
      {
        defaultValues: facilityData,
      });
  const watching = watch();
  const facilityNameRef = useRef();
  const {userData} = useAuth();

  const {
    fields: physicianFields,
    append: physicianAppend,
    remove: physicianRemove,
    move: physicianMove,
  } = useFieldArray({
    control,
    name: "physicians",
  });
  const addPhysician = () => {
    physicianAppend({name: "", NPI: "", contact: ""},
        {shouldFocus: false});
  };
  const [disableAddPhysician, setDisableAddPhysician] = useState(false);
  useEffect(() => {
    if (errors.physicians != null) {
      setDisableAddPhysician(true);
      return;
    }
    if (watching.physicians == null) {
      setDisableAddPhysician(false);
      return;
    }
    if (watching.physicians.length === 0) {
      setDisableAddPhysician(false);
      return;
    }
    const disable = watching.physicians.some(
        (physician) => Object.values(physician).some((item) => item === ''));
    setDisableAddPhysician(disable);
  }, [watching, errors]);
  const onDragEndPhysicianList = ({source, destination}) => {
    if (!destination) {
      return;
    }
    if(source.index === destination.index) {
      return;
    }
    physicianMove(source.index, destination.index);
    if (disableAddPhysician) {
      return;
    }
    updateValue({
      name: "physicians",
      label: "Order",
    });
  };

  const {
    fields: notifyFields,
    append: notifyAppend,
    remove: notifyRemove,
  } = useFieldArray({
    control,
    name: "selfReportNotifyList",
  });
  const addSelfReportNotifyItem = () => {
    notifyAppend({email: "", phone: "", deliveryType: "", dailyTime: "evening"},
        {shouldFocus: false});
  };
  const [disableAddSRNLButton, setDisableAddSRNLButton] = useState(false);
  useEffect(() => {
    if (errors.selfReportNotifyList != null) {
      setDisableAddSRNLButton(true);
      return;
    }
    if (watching.selfReportNotifyList == null) {
      setDisableAddSRNLButton(false);
      return;
    }
    if (watching.selfReportNotifyList.length === 0) {
      setDisableAddSRNLButton(false);
      return;
    }
    const disable = watching.selfReportNotifyList.some(
        (item) => !item.deliveryType);
    setDisableAddSRNLButton(disable);
  }, [watching, errors]);

  const updateValue = ({name, label}) => {
    updateDoc(doc(database.facilities, userData?.preferences?.defaultFacility),
        {[name]: getValues(name)})
    .then(() => {
      enqueueSnackbar(
          label + " updated!",
          {variant: "success"});
    }).catch(() => {
      enqueueSnackbar(
          "Couldn't update " + label + "!",
          {variant: "error"});
    });
  };

  const submitChanges = (data) => {
    console.log(data);
  };

  return (
      <form onSubmit={handleSubmit(submitChanges)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
            >
              <Button
                  variant={"contained"}
                  component={Link}
                  to={"/EditAccount"}
              >
                Change Facility
              </Button>
              <Typography
                  sx={{
                    fontStyle: 'italic',
                    color: "warning.main",
                  }}
              >
                Changes are not applied retroactively.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h5"}>
              Facility Info
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Facility Name"}
                inputProps={{...register("name")}}
                onBlur={() => {
                  updateValue({
                    name: "name",
                    label: "Facility Name",
                  });
                }}
                ref={facilityNameRef}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Facility NPI"}
                inputProps={{...register("NPI")}}
                onBlur={() => {
                  updateValue({
                    name: "NPI",
                    label: "Facility NPI",
                  });
                }}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Facility Address"}
                inputProps={{...register("address")}}
                onBlur={() => {
                  updateValue({
                    name: "address",
                    label: "Facility Address",
                  });
                }}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Facility Source"}
                inputProps={{...register("source")}}
                onBlur={() => {
                  updateValue({
                    name: "source",
                    label: "Facility Source",
                  });
                }}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Lab Name"}
                inputProps={{...register("lab.name")}}
                onBlur={() => {
                  updateValue({
                    name: "lab.name",
                    label: "Lab Name",
                  });
                }}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Lab Address"}
                inputProps={{...register("lab.address")}}
                onBlur={() => {
                  updateValue({
                    name: "lab.address",
                    label: "Lab Address",
                  });
                }}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Lab CLIA #"}
                inputProps={{...register("lab.clia")}}
                onBlur={() => {
                  updateValue({
                    name: "lab.clia",
                    label: "Lab CLIA #",
                  });
                }}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Lab Authorized Official"}
                inputProps={{...register("lab.labAuthorizedOfficial")}}
                onBlur={() => {
                  updateValue({
                    name: "lab.labAuthorizedOfficial",
                    label: "Lab Authorized Official",
                  });
                }}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Consent Link"}
                inputProps={{...register("consentLink")}}
                onBlur={() => {
                  updateValue({
                    name: "consentLink",
                    label: "Consent Link",
                  });
                }}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
                label={"Site Code"}
                inputProps={{...register("siteCode")}}
                onBlur={() => {
                  updateValue({
                    name: "siteCode",
                    label: "Site Code",
                  });
                }}
                required
                variant={"filled"}
                fullWidth
            >
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h5"}>
              Facility Physicians
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PhysicianList
                register={register}
                fields={physicianFields}
                remove={physicianRemove}
                name={"physicians"}
                control={control}
                trigger={trigger}
                errors={errors.physicians}
                update={updateValue}
                onDragEnd={onDragEndPhysicianList}/>
          </Grid>
          <Grid item xs={12}>
            <Button
                disabled={disableAddPhysician}
                onClick={addPhysician}>
              Add Physician
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h5"}>
              Self Report Notifications
            </Typography>
            <LocalTimeRepresentation/>
          </Grid>
          <Grid item xs={12}>
            <NotifyList
                fields={notifyFields}
                remove={notifyRemove}
                name={"selfReportNotifyList"}
                control={control}
                trigger={trigger}
                errors={errors.selfReportNotifyList}
                update={updateValue}
                clearErrors={clearErrors}
                setError={setError}
                watching={watching}/>
          </Grid>
          <Grid item xs={12}>
            <Button
                disabled={disableAddSRNLButton}
                onClick={addSelfReportNotifyItem}>
              Add Staff Member
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h5"}>
              Test Purchasing Settings
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row={true}>
              <ControlledSwitch
                  label={"Allow PCR"}
                  name={"settings.pcr"}
                  update={updateValue}
                  control={control}
              />
              <ControlledSwitch
                  label={"Allow Antigen"}
                  name={"settings.antigen"}
                  update={updateValue}
                  control={control}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
                label={"PCR Price"}
                inputProps={{...register("settings.pcrPrice")}}
                type={"number"}
                onBlur={() => {
                  updateValue({
                    name: "settings.pcrPrice",
                    label: "PCR Price",
                  });
                }}
                disabled={!watching.settings?.pcr}
                InputProps={{
                  startAdornment: <InputAdornment
                      position="start">$</InputAdornment>,
                }}
                fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
                label={"Antigen Price"}
                inputProps={{...register("settings.antigenPrice")}}
                type={"number"}
                disabled={!watching.settings?.antigen}
                onBlur={() => {
                  updateValue({
                    name: "settings.antigenPrice",
                    label: "Antigen Price",
                  });
                }}
                InputProps={{
                  startAdornment: <InputAdornment
                      position="start">$</InputAdornment>,
                }}
                fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
                label={"Combined Price"}
                inputProps={{...register("settings.antigenAndPcr")}}
                onBlur={() => {
                  updateValue({
                    name: "settings.antigenAndPcr",
                    label: "Combined Price",
                  });
                }}
                type={"number"}
                disabled={!(watching.settings?.antigen
                    && watching.settings?.pcr)}
                InputProps={{
                  startAdornment: <InputAdornment
                      position={"start"}>$</InputAdornment>,
                }}
                fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h5"}>
              Registration Fields
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row={true}>
              <ControlledSwitch
                  label={"Collect Id"}
                  name={"settings.id"}
                  update={updateValue}
                  control={control}
              />
              <ControlledSwitch
                  label={"Enforce Id"}
                  name={"settings.enforceId"}
                  update={updateValue}
                  disabled={!watching.settings?.id}
                  control={control}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row={true}>
              <ControlledSwitch
                  label={"Collect Insurance"}
                  name={"settings.insurance"}
                  update={updateValue}
                  control={control}
              />
              <ControlledSwitch
                  label={"Enforce Insurance"}
                  name={"settings.enforceInsurance"}
                  update={updateValue}
                  disabled={!watching.settings?.insurance}
                  control={control}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row={true}>
              <ControlledSwitch
                  label={"Collect Social Security"}
                  name={"settings.ssn"}
                  update={updateValue}
                  control={control}
              />
              <ControlledSwitch
                  label={"Enforce Social Security"}
                  name={"settings.enforceSsn"}
                  update={updateValue}
                  disabled={!watching.settings?.ssn}
                  control={control}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row={true}>
              <ControlledSwitch
                  label={"Collect Vaccine Card"}
                  name={"settings.vaccineCard"}
                  update={updateValue}
                  control={control}
              />
              <ControlledSwitch
                  label={"Enforce Vaccine Card"}
                  name={"settings.enforceVaccineCard"}
                  update={updateValue}
                  disabled={!watching.settings?.vaccineCard}
                  control={control}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row={true}>
              <ControlledSwitch
                  label={"Collect Patient Type"}
                  name={"settings.collectPatientType"}
                  update={updateValue}
                  control={control}
              />
              <ControlledSwitch
                  label={"Enforce Patient Type"}
                  name={"settings.enforcePatientType"}
                  update={updateValue}
                  disabled={!watching.settings?.collectPatientType}
                  control={control}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row={true}>
              <ControlledSwitch
                  label={"Collect Employee Info"}
                  name={"settings.collectEmployeeInfo"}
                  update={updateValue}
                  control={control}
              />
              <ControlledSwitch
                  label={"Enforce Employee Info"}
                  name={"settings.enforceEmployeeInfo"}
                  update={updateValue}
                  disabled={!watching.settings?.collectEmployeeInfo}
                  control={control}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h5"}>
              Facility Features
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row={true}>
              <ControlledSwitch
                  label={"Enable Vouched.ID"}
                  name={"settings.enableVouched"}
                  update={updateValue}
                  control={control}
              />
              <ControlledSwitch
                  label={"Enable Self Reporting"}
                  name={"settings.enableSelfReporting"}
                  update={updateValue}
                  control={control}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
                type={"submit"}
                variant={"contained"}
                color={"primary"}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
  );
}
