import {styled, useTheme} from "@mui/material/styles";
import MuiDrawer from '@mui/material/Drawer';
import {
  alpha,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery
} from '@mui/material';
import SwitchB from "@mui/material/Switch";
import zIndex from '@mui/material/styles/zIndex';
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import {Link} from "react-router-dom";
import {useState} from "react";
import {rolesViews} from "../../const/roles";
import {useAuth} from "../../contexts/CustomAuthProvider";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../components/firebaseConfig";
import NavSection from "../../components/NavSection";
import {navList} from "../../const/navList";

const openedMixin = (theme, hover, drawerWidth) => ({
  width: drawerWidth,
  ...(hover && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DesktopDrawer = styled(MuiDrawer, {
      shouldForwardProp: (prop) =>
          prop !== 'expand'
          && prop !== 'pin'
          && prop !== 'hover'
          && prop !== 'drawerWidth'
    }
)(({theme, expand, hover, drawerWidth}) => ({
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(hover && {position: 'absolute'}),
      ...(expand && {
        ...openedMixin(theme, hover, drawerWidth),
        '& .MuiDrawer-paper': {
          backgroundColor: alpha(theme.palette.background.default, 0.8),
          ...(hover && {backdropFilter: 'blur(6px)'}),
          ...openedMixin(theme, hover, drawerWidth),
        },
      }),
      ...(!expand && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
);

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function DashboardDrawer(props) {
  const {
    pinDesktopDrawer,
    setPinDesktopDrawer,
    drawerWidth,
    setToolbarTitle,
    openMobileDrawer,
    setOpenMobileDrawer,
    expandDesktopDrawer,
    setExpandDesktopDrawer,
  } = props;
  const {
    logout,
    userData,
    darkMode,
    changeDarkMode,
    currentGlobalFacility,
  } = useAuth();
  const [currentUser] = useAuthState(auth);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const mobileDrawer = useMediaQuery(theme.breakpoints.down('md'));

  const togglePinDesktopDrawer = () => {
    if (!currentUser?.uid) {
      return;
    }
    setPinDesktopDrawer(!pinDesktopDrawer, currentUser.uid);
  };
  const onMouseEnterDesktopDrawer = () => {
    if (pinDesktopDrawer) {
      return;
    }
    setExpandDesktopDrawer(true);
  };
  const onMouseLeaveDesktopDrawer = () => {
    if (pinDesktopDrawer) {
      return;
    }
    setExpandDesktopDrawer(false);
  };
  const onMobileDrawerClose = () => {
    setOpenMobileDrawer(false);
  };

  async function handleLogout() {
    setLoading(true);
    try {
      await logout();
      setLoading(false);
    } catch (e) {
      console.log("error" + e);
    }
  }

  const handleThemeChange = (event, newValue) => {
    if (!currentUser?.uid) {
      return;
    }
    changeDarkMode(newValue, currentUser.uid);
  };

  const CustomDrawerHeader = () => {
    return (
        <DrawerHeader>
          <Link className="disableDecoration" to={"/"}>
            <img loading="lazy" src={"/static/logo.png"} alt="LabPort Logo"
                 style={{
                   width: "180px",
                   ...(!expandDesktopDrawer && {position: "relative"}),
                   ...(!expandDesktopDrawer && !mobileDrawer
                       && {left: "149px"}),
                   ...(mobileDrawer && {right: "25px"})
                 }}/>
          </Link>
          {!mobileDrawer &&
              <IconButton onClick={togglePinDesktopDrawer}>
                {pinDesktopDrawer ?
                    <ChevronLeftIcon/>
                    : expandDesktopDrawer &&
                    <PushPinOutlinedIcon/>
                }
              </IconButton>
          }
        </DrawerHeader>
    );
  }

  const AccountStyle = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: expandDesktopDrawer ? "calc(2%)" : "0",
    borderRadius: "12px",
    ...(expandDesktopDrawer && {
      backgroundColor: alpha(theme.palette.primary.main, .15)
    }),
    margin: "10px 10px",
  }));

  const drawerContent = (
      <>
        <Divider/>
        <Link className="disableDecoration" to="/EditAccount" onClick={() => {
          setToolbarTitle("Edit Account");
        }} style={{textDecoration: 'none'}}>
          <AccountStyle>
            <Avatar alt="User Picture" src={currentUser?.photoURL}>
              {!currentUser?.photoURL && currentUser?.email?.charAt(0)}
            </Avatar>
            <Box sx={{
              ml: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>
              <Typography noWrap variant="subtitle2" sx={{
                color: 'text.primary'
              }}>
                {currentUser ? currentUser.email : ''}
              </Typography>
              <Typography noWrap variant="body2" sx={{
                color: 'text.secondary',
                textTransform: 'capitalize',
              }}>
                {rolesViews.display[userData?.roles?.role] || ''}
              </Typography>
              <Typography noWrap variant="body2" sx={{
                color: theme.palette.primary.main
              }}>
                {currentGlobalFacility.toString().length === 0 ?
                    <Skeleton/>
                    : currentGlobalFacility.toString()
                }
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
        <Divider/>
        <NavSection
            navList={navList}
            routeAccess={rolesViews[userData?.roles?.role]?.routeAccess}
            setToolbarTitle={setToolbarTitle}/>
        <ListItem button disabled={loading} onClick={handleLogout}>
          <ListItemIcon> <LogoutOutlinedIcon/> </ListItemIcon>
          <ListItemText primary="Sign Out"/>
        </ListItem>
        <ListItem>
          <ListItemIcon> <NightsStayOutlinedIcon/> </ListItemIcon>
          <ListItemText primary="Dark Mode"/>
          <SwitchB checked={darkMode} onChange={handleThemeChange}/>
        </ListItem>
        <Divider/>
        <Box sx={{flexGrow: 1}}/>
        <ListItem style={{textAlign: "center"}}>
          <ListItemText primary={"©"}/>
          {expandDesktopDrawer &&
              <ListItemText
                  primary={(new Date().getYear() + 1900) + " LABPORT "
                      + process.env.REACT_APP_GIT_SHA}/>
          }
        </ListItem>
      </>
  );

  return (
      <>
        {mobileDrawer &&
            <MuiDrawer
                variant='temporary'
                open={openMobileDrawer}
                onClose={onMobileDrawerClose}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                  },
                }}>
              <CustomDrawerHeader/>
              {drawerContent}
            </MuiDrawer>
        }
        {!mobileDrawer && !pinDesktopDrawer &&
            <DesktopDrawer
                sx={{
                  zIndex: zIndex.drawer + 1
                }}
                variant="permanent"
                expand={expandDesktopDrawer}
                hover={true}
                onMouseEnter={onMouseEnterDesktopDrawer}
                onMouseLeave={onMouseLeaveDesktopDrawer}
                drawerWidth={drawerWidth}>
              <CustomDrawerHeader/>
              {drawerContent}
            </DesktopDrawer>
        }
        {!mobileDrawer &&
            <DesktopDrawer
                variant="permanent"
                expand={pinDesktopDrawer}
                hover={false}
                drawerWidth={drawerWidth}>
              <CustomDrawerHeader/>
              {drawerContent}
            </DesktopDrawer>
        }
      </>
  );
}
