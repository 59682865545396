import {useState} from 'react';
import {Link} from "react-router-dom";
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Switch
} from "@mui/material";
import {auth, database} from "../components/firebaseConfig";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import {useAuth} from "../contexts/CustomAuthProvider";
import {usePatient} from "../contexts/PatientProvider";
import {DATE_RANGES} from "../const/dateRanges";
import {
  doc,
  getDoc,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import {firestoreDataConverter} from "../const/firestoreDataConverter";
import {rolesViews} from "../const/roles";
import {rejectReasons} from "../const/rejectReasons";
import {getFirestoreTimestamp} from "../utils/Utils";
import Page from "../components/Page";
import SelfReportImage from "../components/SelfReportImage";
import {useClientIP} from "../hooks/useClientIP";
import {useAuthState} from "react-firebase-hooks/auth";
import {selfReportNotify} from "../components/notify";
import {useSnackbar} from "notistack";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {auditResults} from "../const/auditResults";
import {TestResultChip} from "../components/TestResultChip";
import {PrettyPhone} from "../components/PrettyPhone";
import {ImageAgeDetail} from "../components/ImageAgeDetail";

export default function SelfReports() {
  const {enqueueSnackbar} = useSnackbar();
  const [emailNotification, setEmailNotification] = useState(true);
  const [textNotification, setTextNotification] = useState(true);

  const {userData} = useAuth();
  const [currentUser] = useAuthState(auth);
  const {changeSelfReport} = usePatient();
  const defaultFacility = userData?.preferences?.defaultFacility;
  const routeAccess = rolesViews[userData?.roles?.role]?.routeAccess;
  const selfReportAccess = (routeAccess ?? []).includes("SelfReport");

  // logic for data limited queries to firebase
  const [dates, setDates] = useState(DATE_RANGES["Last 7 Days"]);
  const rangePickerOnChange = (dates) => {
    if (dates) {
      setDates(dates);
    } else {
      setDates(DATE_RANGES.Today);
    }
  }
  const [data, loading] = useCollectionData(query(database.selfReports,
      where("facility.id", "==", defaultFacility ?? ""),
      where("sample.uploadTime", ">=", dates[0].toDate()),
      where("sample.uploadTime", "<=", dates[1].toDate()),
      orderBy("sample.uploadTime"),
  ).withConverter(firestoreDataConverter));

  const clientIP = useClientIP();

  const [openAuditReport, setOpenAuditReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [anchorRejectMenu, setAnchorRejectMenu] = useState(null);
  const openRejectMenu = Boolean(anchorRejectMenu);

  const auditReport = async (id) => {
    getDoc(doc(database.selfReports, id))
    .then((reportSnapshot) => {
      const reportData = reportSnapshot.data();
      setSelectedReport({
        ...reportData,
        id: id,
      });
      setOpenAuditReport(true);
    });
  };

  const reportResult = async (result, rejectReason) => {
    setOpenAuditReport(false);
    setAnchorRejectMenu(null);
    setDoc(doc(database.selfReports, selectedReport.id),
        {
          audit: {
            result: result,
            ...(rejectReason && {rejectReason: rejectReason}),
            ip: clientIP,
            by: currentUser.uid,
            timestamp: serverTimestamp(),
          },
        },
        {merge: true})
    .then(() => {
      let notificationPayload = {
        selfReportId: selectedReport.id,
        firstName: selectedReport.patient.fname,
        result: result,
        ...(rejectReason && {
          rejectMessage:
          rejectReasons.filter(
              (reason) => reason.name === rejectReason)?.pop()?.message
        }),
        uploadTime: getFirestoreTimestamp(
            selectedReport.sample.uploadTime).toDate(),
        ...(textNotification && {phone: selectedReport.patient.phone}),
        ...(emailNotification && {email: selectedReport.patient.email}),
      };
      selfReportNotify(notificationPayload).then((message) => {
        enqueueSnackbar(message, {variant: 'success'});
      }).catch((error) => {
        enqueueSnackbar(error, {variant: 'error'});
      });
    })
    .catch((error) => {
      enqueueSnackbar(JSON.stringify(error),
          {variant: 'error'});
    });
  };

  const renderButtons = (cell) => {
    return (
        <>
          <Button
              color={"warning"}
              variant={"contained"}
              disabled={cell.row.audit?.result !== auditResults.pending}
              onClick={() => auditReport(cell.id)}>
            Audit
          </Button>
          {selfReportAccess &&
              <Button
                  style={{
                    marginLeft: "10px"
                  }}
                  to={"/SelfReport"}
                  onClick={() => {
                    changeSelfReport(cell.id);
                  }}
                  component={Link}
                  variant={"outlined"}>
                View
              </Button>
          }
        </>
    );
  }

  const renderAuditStatus = (value) => {
    let auditIcon = <Chip color={"info"} label="Pending"/>;
    if (value === auditResults.approve) {
      auditIcon = <Chip color={"success"} label="Approved"/>;
    }
    if (value === auditResults.reject) {
      auditIcon = <Chip color={"error"} label="Rejected"/>;
    }
    return auditIcon;
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      headerAlign: 'center',
    },
    {
      field: "options",
      headerName: "Options",
      width: selfReportAccess ? 180 : 90,
      sortable: false,
      headerAlign: 'center',
      align: "center",
      renderCell: cell => renderButtons(cell),
    },
    {
      field: 'Date',
      headerName: 'Sample Received',
      filterable: false,
      width: 170,
      type: "dateTime",
      headerAlign: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        return Date.parse(param1.value) - Date.parse(param2.value);
      },
      valueGetter: cell =>
          getFirestoreTimestamp(cell.row.sample?.uploadTime).toDate(),
    },
    {
      field: 'patient.fname',
      headerName: 'First name',
      width: 130,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.fname,
    },
    {
      field: 'patient.lname',
      headerName: 'Last name',
      width: 130,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.lname,
    },
    {
      field: 'patient.dob',
      headerName: 'Date of birth',
      type: 'date',
      width: 120,
      headerAlign: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        return Date.parse(param1.value) - Date.parse(param2.value)
      },
      valueGetter: cell => cell.row.patient?.dob,
    },
    {
      field: 'audit.timestamp',
      headerName: 'Audit Timestamp',
      width: 170,
      type: "dateTime",
      headerAlign: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        return Date.parse(param1.value) - Date.parse(param2.value);
      },
      valueGetter: cell => {
        if (cell.row.audit?.timestamp) {
          return getFirestoreTimestamp(cell.row.audit.timestamp).toDate();
        }
      },
    },
    {
      field: 'audit.result',
      headerName: 'Audit',
      width: 95,
      headerAlign: 'left',
      valueGetter: cell => cell.row.audit?.result ?? "Pending",
      renderCell: cell => renderAuditStatus(cell.value),
    },
    {
      field: 'sample.result',
      headerName: 'Result',
      width: 90,
      headerAlign: 'left',
      valueGetter: cell => cell.row.sample?.user?.result,
      renderCell: cell => <TestResultChip value={cell.value}/>,
    },
    {
      field: 'patient.phone',
      headerName: 'Phone',
      width: 170,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.phone,
      renderCell: cell => <PrettyPhone phone={cell.row.patient?.phone}/>,
    },
    {
      field: 'patient.email',
      headerName: 'Email',
      width: 240,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.email,
    },
  ];

  return (
      <Page title={"LabPort | Self Reports"}>
        <Dialog
            open={openAuditReport}
            onClose={() => setOpenAuditReport(false)}
        >
          <DialogTitle>
            Audit Test Result
          </DialogTitle>
          <DialogContent
              style={{
                paddingTop: "1rem"
              }}
          >
            <SelfReportImage
                selfReportId={selectedReport?.id}
            />
            {selectedReport?.sample?.imageMeta?.lastModified &&
                <ImageAgeDetail
                    lastModified={getFirestoreTimestamp(
                        selectedReport?.sample?.imageMeta?.lastModified).toDate()}
                    uploadTime={getFirestoreTimestamp(
                        selectedReport?.sample?.uploadTime).toDate()}
                />
            }
            <DialogContentText>
              Test Upload Date: {
              getFirestoreTimestamp(selectedReport?.sample?.uploadTime)
              .toDate().toLocaleString()}
            </DialogContentText>
            <DialogContentText>
              Reported Test Result: {selectedReport?.sample?.user?.result}
            </DialogContentText>
            <DialogContentText>
              Reported Test Date: {
              getFirestoreTimestamp(selectedReport?.sample?.user?.date)
              .toDate().toLocaleString()}
            </DialogContentText>
            <DialogContentText>
              Reported Test Type: {selectedReport?.sample?.user?.testType}
            </DialogContentText>
            <DialogContentText>
              Patient IP address: {selectedReport?.sample?.user?.ip}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
                color={"success"}
                variant={"contained"}
                onClick={() => reportResult(auditResults.approve)}
            >
              Approve
            </Button>
            <Button
                color={"error"}
                variant={openRejectMenu ? "outlined" : "contained"}
                onClick={(event) => setAnchorRejectMenu(event.currentTarget)}
                endIcon={<KeyboardArrowDownIcon/>}
            >
              Reject
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
            anchorEl={anchorRejectMenu}
            open={openRejectMenu}
            onClose={() => setAnchorRejectMenu(null)}
        >
          {rejectReasons.map((reason) => (
              <MenuItem
                  key={reason.name}
                  onClick={() => reportResult(auditResults.reject, reason.name)}
              >
                {reason.title}
              </MenuItem>
          ))}
        </Menu>
        <FormGroup row={true}>
          <FormControlLabel
              control={<Switch
                  checked={emailNotification}
                  onChange={(e) => {
                    setEmailNotification(e.target.checked)
                  }}
                  name={"Send Email Notifications"}
                  color={"primary"}
              />}
              label={"Send Email Notifications"}
          />
          <FormControlLabel
              control={<Switch
                  checked={textNotification}
                  onChange={(e) => {
                    setTextNotification(e.target.checked)
                  }}
                  name={"Send Text Notifications"}
                  color={"primary"}
              />}
              label={"Send Text Notifications"}
          />
        </FormGroup>
        <CustomDataGrid
            dates={dates}
            rangePickerOnChange={rangePickerOnChange}
            localVar={"SelfReport"}
            columns={columns}
            rows={data ?? []}
            loading={loading}
            disableSelectionOnClick
            initialColumnVisibility={{
              id: false,
            }}
        />
      </Page>
  )
}
