import {useRef, useState} from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc
} from "firebase/firestore";
import {useForm} from "react-hook-form";
import {useAuthState} from "react-firebase-hooks/auth";
import parsePhoneNumber from 'libphonenumber-js'
import {LoadingButton} from '@mui/lab';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useAuth} from "../contexts/CustomAuthProvider";
import {auth, database} from "./firebaseConfig";
import {usaStates} from "../const/usaStates";
import {insurances} from "../const/insurances";
import {Link, useNavigate} from "react-router-dom";
import {races} from "../const/races"
import {sexes} from "../const/sexes"
import {ethnicities} from "../const/ethnicities"
import {entities} from "../const/entities"
import {identificationTypes} from "../const/identificationTypes"
import {vaccines} from "../const/vaccines";
import {
  ControlledAutocomplete,
  ControlledDesktopDatePicker,
  ControlledMuiPhoneNumber,
} from "../utils/ControlledComponents";
import {rolesViews} from "../const/roles";
import {states} from "../const/states";
import {countries} from "../const/countries";
import {
  getDirtyValues,
  getFirestoreTimestamp,
  syncCreditToCheckin
} from "../utils/Utils";
import MasonryImageList from "./MasonryImageList";
import {CustomSignaturePad} from "./SignaturePad";
import {useClientIP} from "../hooks/useClientIP";

export default function EditPatientForm(props) {
  const {facilityData, patientData} = props;
  const [initialData] = useState(patientData);
  const {
    register,
    watch,
    control,
    getValues,
    formState: {isSubmitting, dirtyFields},
    handleSubmit
  } = useForm(
      {
        defaultValues: patientData,
      });
  const watchingAddressCountry = watch("address.country");
  const watchBillTo = watch("billTo");
  const watchingVaccineType = watch("vaccine.type");
  const sweetAlert = withReactContent(Swal);
  const [modifyingCredits, setModifyingCredits] = useState(false);
  const navigate = useNavigate();
  const {userData} = useAuth();
  const signPad = useRef(null);
  const [currentUser] = useAuthState(auth);
  const clientIP = useClientIP();

  const onError = () => {
    sweetAlert.fire({
      title: <p>Please correct invalid fields.</p>,
      icon: "error",
      timer: 3000,
      toast: true,
      position: 'bottom-end',
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
  }

  const onSubmit = (data) => {
    if (signPad.current.isEmpty()) {
      sweetAlert.fire({
        title: <p>Please sign the form!</p>,
        icon: "error",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
      return;
    }
    data.phone = parsePhoneNumber(data.phone)?.number;
    const pcrCredit = parseInt(data.credit.pcr);
    data.credit.pcr = isNaN(pcrCredit) ? 0 : pcrCredit;
    const antigenCredit = parseInt(data.credit.antigen);
    data.credit.antigen = isNaN(antigenCredit) ? 0 : antigenCredit;
    //convert all timestamps into Firestore Timestamp objects
    if (data?.author?.timestamp) {
      data.author.timestamp = getFirestoreTimestamp(data.author.timestamp);
    }
    const signatureImage = signPad.current.toDataURL("image/svg+xml");
    const newEdit = {
      facilityId: data.facilityId,
      by: currentUser.uid,
      ip: clientIP,
      timestamp: serverTimestamp(),
      signature: signatureImage,
      editedFields: getDirtyValues(dirtyFields, data, initialData),
    };
    const addEdit = addDoc(collection(doc(database.patients, data.id), "Edits"),
        newEdit);
    const updatePatient = setDoc(doc(database.patients, data.id), data,
        {merge: true});
    Promise.all([
      addEdit,
      updatePatient
    ]).then(() => {
      syncCreditToCheckin(data.id);
      sweetAlert.fire({
        title: <p>Updated Patient!</p>,
        icon: "success",
        text: "Your patient has been updated. "
            + "Please note that any previously generated requisition forms will not be changed. "
            + "Updated information will show on future requisition forms.",
      }).then();
      navigate("/PatientRecords");
    }).catch((error) => {
      sweetAlert.fire({
        title: <p>Could not update patient.</p>,
        text: error,
        icon: "error",
      }).then();
    });
  }

  const toggleModifyCredits = () => {
    setModifyingCredits(!modifyingCredits)
  }

  const textPaymentLink = () => {
    addDoc(database.sendSMS, {
      "to": parsePhoneNumber(getValues("phone"))?.number,
      "body": "LabPort🧪: " + getValues("fname") +
          ", here is the requested payment link for testing https://register.labport.app/cart?pid="
          + getValues("id") + "&site=" + getValues("facilityId"),
    }).then(() => {
      sweetAlert.fire({
        title: <p>Payment link was queued to be sent.</p>,
        icon: "success",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
    }).catch((e) => {
      sweetAlert.fire({
        title: <p>{JSON.stringify(e)}</p>,
        icon: "error",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
    })
  }

  const textVaccineLink = () => {
    addDoc(database.sendSMS, {
      "to": parsePhoneNumber(getValues("phone"))?.number,
      "body": "LabPort🧪: " + getValues("fname") +
          ", here is the requested link for uploading your vaccine ID https://register.labport.app/vaccine?pid="
          + getValues("id") + "&site=" + getValues("facilityId"),
    }).then(() => {
      sweetAlert.fire({
        title: <p>Vaccine link was queued to be sent.</p>,
        icon: "success",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
    }).catch((e) => {
      sweetAlert.fire({
        title: <p>{JSON.stringify(e)}</p>,
        icon: "error",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
    })
  }

  return (
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <Link to={"/PatientRecords"}>
              <Avatar variant={"square"}>
                <ArrowBackIosIcon/>
              </Avatar>
            </Link>
          </Grid>
          {/*Patient Info*/}
          <Grid item xs={11}>
            <Typography variant={"h4"}>
              Patient Info
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <TextField
                inputProps={{...register("fname")}}
                label={"First Name"}
                autoComplete={"new-password"}
                required
                disabled={!rolesViews[userData?.roles?.role]?.fullyEditPatients}
                helperText={!rolesViews[userData?.roles?.role]?.fullyEditPatients
                    && "Please contact us to edit disabled entries."}
                fullWidth/>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <TextField
                inputProps={{...register("lname")}}
                label={"Last Name"}
                autoComplete={"new-password"}
                required
                disabled={!rolesViews[userData?.roles?.role]?.fullyEditPatients}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledDesktopDatePicker
                name={"dob"}
                control={control}
                label={"Date of Birth"}
                autoComplete={"bday"}
                required
                inputFormat={"MM/dd/yyyy"}
                minimumDate={new Date("1900-01-01")}
                disabled={!rolesViews[userData?.roles?.role]?.fullyEditPatients}
                disableFuture/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledMuiPhoneNumber
                name={"phone"}
                control={control}
                label={"Phone Number"}
                autoComplete={"new-password"}
                required
                type={"tel"}
                variant={"outlined"}
                defaultCountry={'us'}
                disableAreaCodes
                fullWidth/>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <TextField
                inputProps={{...register("email")}}
                label={"Email"}
                autoComplete={"new-password"}
                required
                type={"email"}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"sex"}
                control={control}
                options={sexes}
                label={"Sex"}
                autoComplete={"new-password"}
                required/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"race"}
                control={control}
                options={races}
                label={"Race"}
                autoComplete={"new-password"}
                required/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"ethnicity"}
                control={control}
                options={ethnicities}
                label={"Ethnicity"}
                autoComplete={"new-password"}
                required/>
          </Grid>
          {facilityData?.patientCategories?.length > 0 &&
              <Grid item lg={3} sm={6} xs={12}>
                <ControlledAutocomplete
                    name={"patientType"}
                    control={control}
                    options={facilityData.patientCategories}
                    label={"Patient Category"}
                    autoComplete={"new-password"}
                    required={facilityData.settings?.enforcePatientType}/>
              </Grid>
          }
          {/*Employment Info*/}
          {facilityData?.settings?.collectEmployeeInfo &&
              <>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h4"}>
                    Employment Info
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <TextField
                      inputProps={{...register("meta.employeeId")}}
                      label={"Employee ID"}
                      required
                      fullWidth/>
                </Grid>
                {facilityData?.departments?.length > 0 &&
                    <Grid item lg={4} sm={6} xs={12}>
                      <ControlledAutocomplete
                          name={"meta.employeeDept"}
                          control={control}
                          options={facilityData.departments}
                          label={"Department"}
                          autoComplete={"new-password"}
                          required/>
                    </Grid>
                }
                {facilityData?.locations?.length > 0 &&
                    <Grid item lg={4} sm={6} xs={12}>
                      <ControlledAutocomplete
                          name={"meta.employeeLocation"}
                          control={control}
                          options={facilityData.locations}
                          label={"Location"}
                          autoComplete={"new-password"}
                          required/>
                    </Grid>
                }
              </>
          }
          {/*Location Details*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Location Details
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
                inputProps={{...register("address.line1")}}
                label={"Address Line 1"}
                autoComplete={"new-password"}
                required
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("address.city")}}
                label={"City"}
                autoComplete={"new-password"}
                required
                fullWidth/>
          </Grid>
          {(watchingAddressCountry === "United States"
                  || watchingAddressCountry === undefined) &&
              <Grid item lg={3} sm={6} xs={12}>
                <ControlledAutocomplete
                    name={"address.state"}
                    control={control}
                    options={usaStates}
                    label={"State"}
                    autoComplete={"new-password"}
                    required/>
              </Grid>
          }
          {(watchingAddressCountry === "United States"
                  || watchingAddressCountry === "Canada"
                  || watchingAddressCountry === undefined) &&
              <Grid item lg={2} sm={6} xs={12}>
                <TextField
                    inputProps={{...register("address.zip")}}
                    label={"Postal Code"}
                    autoComplete={"new-password"}
                    required
                    fullWidth/>
              </Grid>
          }
          <Grid item lg={4} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"address.country"}
                control={control}
                label={"Country of Residence"}
                options={countries}
                autoComplete={"new-password"}
                required/>
          </Grid>
          {/*Billing*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Billing
            </Typography>
          </Grid>
          <Grid item lg={2} sm={6} xs={12}>
            <TextField
                inputProps={{...register("credit.antigen")}}
                disabled={!modifyingCredits}
                label={"Antigen Credit(s)"}
                autoComplete={"new-password"}
                defaultValue={"0"}
                type={"number"}
                required
                fullWidth/>
          </Grid>
          <Grid item lg={2} sm={6} xs={12}>
            <TextField
                inputProps={{...register("credit.pcr")}}
                disabled={!modifyingCredits}
                label={"PCR Credit(s)"}
                autoComplete={"new-password"}
                defaultValue={"0"}
                type={"number"}
                required
                fullWidth/>
          </Grid>
          <Grid item lg={2} sm={6} xs={12}>
            <Button
                variant={"contained"}
                color={"info"}
                onClick={toggleModifyCredits}
                disabled={!rolesViews[userData?.roles?.role]?.modifyCredits}>
              Modify credits
            </Button>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Button
                variant={"contained"}
                color={"success"}
                onClick={textPaymentLink}>
              Text payment link
            </Button>
          </Grid>
          {/*Insurance Info*/}
          <Grid item lg={3} sm={6} xs={6}>
            <ControlledAutocomplete
                name={"billTo"}
                control={control}
                options={entities}
                label={"Bill to"}
                autoComplete={"new-password"}
                required={facilityData?.settings?.insurance}/>
          </Grid>
          {watchBillTo === "Insurance" &&
              <>
                <Grid item xs={12}>
                  <Typography variant={"h4"}>
                    Insurance Info
                  </Typography>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <ControlledAutocomplete
                      name={"insurance.carrier"}
                      control={control}
                      label={"Insurance Providers"}
                      options={insurances}
                      autoComplete={"new-password"}
                      freeSolo
                      required={facilityData?.settings?.insurance}/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <TextField
                      inputProps={{...register("insurance.policyNumber")}}
                      label={"Policy #"}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.insurance}
                      fullWidth/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <TextField
                      inputProps={{...register("insurance.groupNumber")}}
                      label={"Group #"}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.insurance}
                      fullWidth/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <TextField
                      inputProps={{...register("insurance.subscriberName")}}
                      label={"Subscriber Name"}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.insurance}
                      fullWidth/>
                </Grid>
              </>
          }
          {/*Identification Details*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Identification Details
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"identification.type"}
                control={control}
                label={"Identification Type"}
                options={Object.values(identificationTypes)}
                autoComplete={"new-password"}
                freeSolo
                required={facilityData?.settings?.id}/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("identification.number")}}
                label={"Identification #"}
                autoComplete={"new-password"}
                required={facilityData?.settings?.id}
                fullWidth/>
          </Grid>
          <Grid item lg={2} sm={6} xs={12}>
            <ControlledDesktopDatePicker
                name={"identification.exp"}
                control={control}
                label={"Expiration Date"}
                required={facilityData?.settings?.id}
                minimumDate={new Date("1900-01-01")}
                inputFormat={"MM/dd/yyyy"}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"identification.state"}
                control={control}
                label={"Issuance State"}
                options={states}
                autoComplete={"new-password"}
                required={facilityData?.settings?.id}/>
          </Grid>
          {/*Vaccine Info*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Vaccine Status
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"vaccine.type"}
                control={control}
                label={"Vaccine(s) taken"}
                options={vaccines}
                required={facilityData?.settings?.enforceVaccineCard}
                multiple
                autoComplete={"new-password"}
                autoCompleteProps={{
                  limitTags: 1,
                }}/>
          </Grid>
          <Grid item lg={2} sm={6} xs={12}>
            <ControlledDesktopDatePicker
                name={"vaccine.firstDoseDate"}
                control={control}
                label={"Date of 1st Dose"}
                required={facilityData?.settings?.enforceVaccineCard}
                inputFormat={"MM/dd/yyyy"}
                minimumDate={new Date("2020-01-01")}
                disableFuture/>
          </Grid>
          {(watchingVaccineType?.some(v => v.includes("Moderna"))
                  || watchingVaccineType?.some(v => v.includes("Pfizer"))) &&
              <Grid item lg={2} sm={6} xs={12}>
                <ControlledDesktopDatePicker
                    name={"vaccine.secondDoseDate"}
                    control={control}
                    label={"Date of 2nd Dose"}
                    required={facilityData?.settings?.enforceVaccineCard}
                    inputFormat={"MM/dd/yyyy"}
                    minimumDate={new Date("2020-01-01")}
                    disableFuture/>
              </Grid>
          }
          <Grid item lg={2} sm={6} xs={12}>
            <ControlledDesktopDatePicker
                name={"vaccine.boosterDate"}
                control={control}
                label={"Date of Booster"}
                required={facilityData?.settings?.enforceVaccineCard}
                inputFormat={"MM/dd/yyyy"}
                minimumDate={new Date("2020-01-01")}
                disableFuture/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Button
                variant={"contained"}
                color={"success"}
                disabled
                onClick={textVaccineLink}>
              Text upload link
            </Button>
          </Grid>
          {rolesViews[userData?.roles?.role]?.viewImageFiles &&
              <>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h4"}>
                    Image File(s)
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <MasonryImageList folderId={patientData.id}/>
                </Grid>
              </>
          }
          {/*Sign and Submit*/}
          <Grid item xs={12}>
            <FormControlLabel
                value={"end"}
                control={<Checkbox required color={"primary"}/>}
                label="By submitting this form I am verifying that
                 the information above is correct and understand that
                 past patient records may continue to be stored by
                 LabPort for compliance purposes."
                labelPlacement={"end"}/>
          </Grid>
          <Grid item xs={12}>
            <CustomSignaturePad ref={signPad}/>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
                loading={isSubmitting}
                type={"submit"}
                variant={"contained"}
                color={"primary"}
                fullWidth>
              Edit Patient
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
  );
}
