import {useState} from "react";
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {useAuthState} from "react-firebase-hooks/auth";
import {Button, FormControlLabel, FormGroup, Switch} from "@mui/material";
import {useAuth} from "../contexts/CustomAuthProvider";
import {auth, database} from "../components/firebaseConfig";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {resultNotify} from "../components/notify";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import {
  doc,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where
} from "firebase/firestore";
import {firestoreDataConverter} from "../const/firestoreDataConverter";
import {useClientIP} from "../hooks/useClientIP";
import Page from "../components/Page";

export default function AntigenReporting() {

  const [emailNotification, setEmailNotification] = useState(true);
  const [textNotification, setTextNotification] = useState(true);

  const {userData} = useAuth();
  const [currentUser] = useAuthState(auth);

  const clientIP = useClientIP();

  const sweetAlert = withReactContent(Swal);

  const defaultFacility = userData?.preferences?.defaultFacility;
  const [data, loading] = useCollectionData(query(database.reqForms,
      where("facility.id", "==", defaultFacility ?? ""),
      where("sample.testPanel", "==", "ANTIGEN - COVID-19 SARS-COV-2"),
      where("result", "==", null),
      orderBy("sample.collectionTime", "asc"),
  ).withConverter(firestoreDataConverter));

  const columns = [
    //headers [ results, first name, last name, DOB, barcode ],
    {
      field: 'id',
      headerName: 'Req Form ID',
      width: 200,
      headerAlign: 'left',
      valueGetter: (data) => {
        return data.row.id
      },
    },
    {
      field: "=",
      headerName: "Set Result",
      width: 230,
      sortable: false,
      headerAlign: 'center',
      renderCell: (GridCellParams) => {
        function reportResult(result) {
          setDoc(doc(database.reqForms, GridCellParams.row.id), {
            author: {
              "resultsBy": currentUser.uid,
              "results_timestamp": serverTimestamp()
            },
            "result": result,
          }, {merge: true}).then(async () => {
            let notificationPayload = {
              reqFormid: GridCellParams.row.id,
              fname: GridCellParams.row.patient.fname,
              collectionTime: GridCellParams.row.sample.collectionTime.seconds
            };
            if (textNotification) {
              notificationPayload.phone = GridCellParams.row.patient.phone;
            }
            if (emailNotification) {
              notificationPayload.email = GridCellParams.row.patient.email;
            }
            if (textNotification || emailNotification) {
              resultNotify(notificationPayload).then((message) => {
                sweetAlert.fire({
                  title: <p>{message}</p>,
                  icon: "success",
                  toast: true,
                  position: 'bottom-end',
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                })
              }).catch(e => {
                sweetAlert.fire({
                  title: <p>{e}</p>,
                  icon: "error",
                  toast: true,
                  position: 'bottom-end',
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                })
              })
            } else {
              sweetAlert.fire({
                title: <p>Antigen result successfully uploaded</p>,
                icon: "success",
                toast: true,
                position: 'bottom-end',
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
              })
            }
          }).catch((e) => {
            sweetAlert.fire({
              title: <p>{JSON.stringify(e)}</p>,
              icon: "error",
              toast: true,
              position: 'bottom-end',
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            })
          });
        }

        return <>
          <Button style={{
            textDecoration: "none",
            marginRight: "10px",
            backgroundColor: "#00e51b",
            color: "#000000"
          }} variant={"contained"} color={"primary"}
                  onClick={() => reportResult("Negative")}>Neg</Button>
          <Button style={{
            textDecoration: "none",
            marginRight: "10px",
            backgroundColor: "#025fe0",
            color: "#d2d2d2"
          }} variant={"contained"} color={"secondary"}
                  onClick={() => reportResult("Inconclusive")}>Inc</Button>
          <Button style={{
            textDecoration: "none",
            marginRight: "10px",
            backgroundColor: "#e01a26",
            color: "#d2d2d2"
          }} variant={"contained"} color={"secondary"}
                  onClick={() => reportResult("Positive")}>Pos</Button>
        </>
      }
    },
    {
      field: 'Date',
      headerName: 'Sample Timestamp',
      width: 200,
      type: "date",
      headerAlign: 'left',
      valueGetter: (data) => {
        return new Date(
            data.row.sample.collectionTime.seconds * 1000).toLocaleString()
      },
    },
    {
      field: 'fname',
      headerName: 'First name', width: 130, headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.patient.fname
        }
      },
    },
    {
      field: 'lname',
      headerName: 'Last name', width: 130, headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.patient.lname
        }
      },
    },
    {
      field: 'barcode',
      headerName: 'Barcode', width: 130, headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.sample.barcode
        }
      },
    },
    {
      field: 'dob',
      headerName: 'Date of birth',
      type: 'date',
      width: 140,
      headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.patient.dob
        }
      },
    }
  ];

  return (
      <Page title={"LabPort | Antigen Reporting"}>
        <FormGroup row={true}>
          <FormControlLabel
              control={<Switch
                  checked={emailNotification}
                  onChange={(e) => {
                    setEmailNotification(e.target.checked)
                  }}
                  name="Send Email Notifications"
                  color="primary"
              />}
              label="Send Email Notifications"
          />
          <FormControlLabel
              control={<Switch
                  checked={textNotification}
                  onChange={(e) => {
                    setTextNotification(e.target.checked)
                  }}
                  name="Send Text Notifications"
                  color="primary"
              />}
              label="Send Text Notifications"
          />
        </FormGroup>
        <CustomDataGrid
            localVar={"Antigen"}
            columns={columns}
            rows={data ?? []}
            loading={loading}
            disableSelectionOnClick
            initialColumnVisibility={{
              id: false,
            }}
        />
      </Page>
  );
}
