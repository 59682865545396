import {useState} from "react";
import Page from "../components/Page";
import {addDoc, serverTimestamp} from "firebase/firestore";
import {auth, database} from "../components/firebaseConfig";
import {Grid, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import Swal from "sweetalert2";
import axios from "axios";
import {envConfig} from "../envConfig";
import withReactContent from "sweetalert2-react-content";
import {useForm} from "react-hook-form";
import {useAuth} from "../contexts/CustomAuthProvider";
import {useAuthState} from "react-firebase-hooks/auth";
import {useClientIP} from "../hooks/useClientIP";

export default function CreateFacility() {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const sweetAlert = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const {userData} = useAuth();
  const [currentUser] = useAuthState(auth);
  const clientIP = useClientIP();

  const onError = () => {
    sweetAlert.fire({
      title: <p>Please correct invalid fields.</p>,
      icon: "error",
      timer: 3000,
      toast: true,
      position: 'bottom-end',
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
  }

  const onSubmit = async (data) => {
    return;
    setLoading(true);
    let response;
    try {
      response = await axios.post(
          envConfig.currentAppEngineVerify + "checkDuplicates",
          {
            dob: data.dob,
            phone: data.phone,
          },
          // {headers: {recaptcha: token}}
      );
    } catch (error) {
      console.log(error.response)
      sweetAlert.fire({
        title: <p>{error.response?.data?.status}</p>,
        icon: "error",
        timer: 6000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
      setLoading(false);
      return;
    }
    if (response?.data?.duplicate) {
      sweetAlert.fire({
        title: <p>Duplicate record found! Please contact your friendly
          neighborhood admin for support.</p>,
        icon: "error",
        timer: 6000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
      setLoading(false);
      return;
    }
    data.facilityId = userData?.preferences?.defaultFacility;
    data.author = {
      "submittedBy": currentUser.uid,
      "timestamp": serverTimestamp(),
      ip: clientIP,
    };
    addDoc(database.users, data).then(() => {
      sweetAlert.fire({
        title: <p>Added User!</p>,
        icon: "success",
        text: "The user has been to this facility.",
      }).then();
      setLoading(false);
    }).catch((error) => {
      sweetAlert.fire({
        title: <p>Could not add user.</p>,
        text: error,
        icon: "error",
      }).then();
      setLoading(false);
    });
  }

  return (
      <Page title={"LabPort | Create User"}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={12}>
              <TextField
                  inputProps={{...register("email")}}
                  label={"Email"}
                  autoComplete={"new-password"}
                  required
                  type={"email"}
                  fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                  loading={loading}
                  disabled={true}
                  type={"submit"}
                  variant={"contained"}
                  fullWidth>
                Send Email link
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Page>
  );
}
